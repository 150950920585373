import React from "react";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Img from "gatsby-image";
import BoxTypo from "../components/BoxTypo";
import Grid from "@material-ui/core/Grid";
import { useStaticQuery, graphql, Link } from "gatsby";

export const StoreInfo = (props) => {
  const { StoreArray } = props;
  const data = useStaticQuery(graphql`
    query {
      topImage: file(relativePath: { eq: "piccante-Top-Img.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dinerImage: file(relativePath: { eq: "diner-picture.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Catervre: file(relativePath: { eq: "catervre.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HitoyoshiImg: file(relativePath: { eq: "hitoyoshi-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vactorLine: file(relativePath: { eq: "vactorLine.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      IlMacaone: file(relativePath: { eq: "IlMacaone.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      caterverleft: file(relativePath: { eq: "caterver-left.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hitoyoshi: file(relativePath: { eq: "hitoyoshi.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pivot: file(relativePath: { eq: "pivot.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ReverceDinerPicture: file(
        relativePath: { eq: "reverce-diner-picture.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      {(StoreArray || []).map((elems) => {
        return (
          <>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ margin: "20px 0" }}
            >
              <Grid item xs={12} sm={5} lg={3}>
                <Box
                  xs={6}
                  // mt="50px"
                  width={["80%", "100%", "314px"]}
                  height={["80%", "100%", "314px"]}
                  m={"auto"}
                >
                  <Img fluid={elems.assetUrl} />
                </Box>
              </Grid>
              <Grid item xs={12} md={5} lg={4} style={{ position: "relative" }}>
                <Box
                  width={["100%", "100%", "100%"]}
                  m={"auto"}
                  mb={["10px", "30px", "100px"]}
                  mt={["10px", "20px", "20px"]}
                  ml={["0px", "0px", "120px"]}
                >
                  <BoxTypo
                    textAlign={["left", "center", "left"]}
                    width={["70%", "50%", "100%"]}
                    m={"auto"}
                    fs={["10px", "10px", "14px"]}
                    fw="bold"
                  >
                    {elems.storeName}
                  </BoxTypo>
                  <BoxTypo
                    textAlign={["left", "center", "left"]}
                    width={["70%", "50%", "100%"]}
                    m={"auto"}
                    fs={["16px", "16px", "20px"]}
                    fw="bold"
                  >
                    {elems.title1}
                    <br />
                    {elems.title2}
                  </BoxTypo>
                  <BoxTypo
                    textAlign={["left", "center", "left"]}
                    width={["70%", "50%", "100%"]}
                    m={"auto"}
                    mt={["10px", "20px", "20px"]}
                    fs={["12px", "12px", "16px"]}
                  >
                    {elems.cont1}
                    <br />
                    {elems.cont2}
                    <br />
                    {elems.cont3}
                    <br />
                    {elems.cont4}
                  </BoxTypo>
                  <Box
                    textAlign={["center", "center", "left"]}
                    position={["static", "static", "absolute"]}
                    style={{ bottom: "0" }}
                    mt={["50px", "", ""]}
                  >
                    <Link to={elems.more} style={{ padding: "0" }}>
                      <Box
                        textAlign="center"
                        bgcolor={elems.bg}
                        margin={["auto", "auto", ""]}
                        width={["40%", "30%", "100%"]}
                        mt={["20px", "20px", "20px"]}
                      >
                        <BoxTypo
                          color="#fff"
                          p={["10px 20px", " 10px 30px", "10px 50px"]}
                        >
                          More info
                        </BoxTypo>
                      </Box>
                    </Link>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box margin="auto" textAlign="center" width="30%" pt={8} pb={15}>
              <Img fluid={data.vactorLine.childImageSharp.fluid} />
            </Box>
          </>
        );
      })}
    </>
  );
};
