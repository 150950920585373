import React from "react";
import Img from "gatsby-image";
import "./index.css";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import RecentNews from "../components/RecentNews";
import Profile from "../components/Profile";
import Media from "../components/Media";
import Content from "../components/content";
import Footer from "../components/footer";
import Pastevent from "../components/pastevent";
import { Hidden } from "@material-ui/core";
import BoxTypo from "../components/BoxTypo";
import NewsArea from "../components/newsArea";
import { StoreInfo } from "../components/StoreInfo";
import MediaQuery from "react-responsive";
import Pics from "../components/pics";

export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      topImage: file(relativePath: { eq: "ilm_fv.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dinerImage: file(relativePath: { eq: "diner-picture.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Caterver: file(relativePath: { eq: "caterver.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      HitoyoshiImg: file(relativePath: { eq: "hitoyoshi-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FB: file(relativePath: { eq: "768px-Facebook_Logo_(2019) 1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      IG: file(relativePath: { eq: "200px-Instagram_logo_2016.svg 1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ReverceDinerPicture: file(
        relativePath: { eq: "reverce-diner-picture.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMicrocmsNews {
        edges {
          node {
            newsId
            title
            date
            content
            image {
              url
            }
          }
        }
      }
    }
  `);

  const companyLists = ["Address", "Tell", "Time"];
  const companyListsSec = [
    "東京都港区白金6-23-1",
    "03-6875-0618",
    "12:00-20:00 定休日(土・日)",
  ];

  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#fff">
        <Header />
        <Box
          className="fv"
          width="100%"
          margin="auto"
          py="32px"
          position="relative"
          bgcolor="#f7f6f5"
        >
          <Img fluid={data.topImage.childImageSharp.fluid} />
          <Box m="50px" textAlign="center">
            <BoxTypo fs={["24px", "36px", "48px"]} fw="700">
              “Menu .
            </BoxTypo>
            <BoxTypo fs="14px">真心込めて、お料理を提供致します。</BoxTypo>
          </Box>
          <Box width="90%" maxWidth="800px" m={"auto"} bgcolor="#E2D9D0">
            <BoxTypo
              fs="18px"
              fw="700"
              style={{
                width: "90%",
                margin: "auto",
                paddingTop: "64px",
                maxWidth: "500px",
                marginBottom: "8px",
              }}
            >
              看板メニュー
            </BoxTypo>
            <table style={{ width: "90%", margin: "auto", maxWidth: "500px" }}>
              <tbody>
                <tr>
                  <td>
                    <BoxTypo fs="14px">トリッパのトマト煮込み</BoxTypo>
                  </td>
                  <td style={{ width: "30%" }}>
                    <BoxTypo fs="14px">1,400円</BoxTypo>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BoxTypo fs="14px">ランプレドットのスープ煮</BoxTypo>
                  </td>
                  <td>
                    <BoxTypo fs="14px">1,400円</BoxTypo>
                  </td>
                </tr>
              </tbody>
            </table>
            <BoxTypo
              fs="18px"
              mt={6}
              fw="700"
              style={{
                width: "90%",
                margin: "auto",
                marginTop: "32px",
                maxWidth: "500px",
                marginBottom: "8px",
              }}
            >
              日替わり
            </BoxTypo>
            <table style={{ width: "90%", margin: "auto", maxWidth: "500px" }}>
              <tbody>
                <tr>
                  <td>
                    <BoxTypo fs="14px">大山鶏のレバーペースト</BoxTypo>
                  </td>
                  <td style={{ width: "30%" }}>
                    <BoxTypo fs="14px">900円</BoxTypo>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BoxTypo fs="14px">ブラッティーナのカプレーゼ</BoxTypo>
                  </td>
                  <td>
                    <BoxTypo fs="14px">1,400円</BoxTypo>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BoxTypo fs="14px">トリッパマリネのサラダ</BoxTypo>
                  </td>
                  <td>
                    <BoxTypo fs="14px">1,400円</BoxTypo>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BoxTypo fs="14px">自家製サルシッチ</BoxTypo>
                  </td>
                  <td>
                    <BoxTypo fs="14px">1,600円</BoxTypo>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BoxTypo fs="14px">合鴨のコンフィ</BoxTypo>
                  </td>
                  <td>
                    <BoxTypo fs="14px">2,400円</BoxTypo>
                  </td>
                </tr>
              </tbody>
            </table>
            <BoxTypo
              fs="18px"
              fw="700"
              style={{
                width: "90%",
                margin: "auto",
                marginTop: "32px",
                maxWidth: "500px",
                marginBottom: "8px",
              }}
            >
              ドリンク
            </BoxTypo>
            <table
              style={{
                width: "90%",
                margin: "auto",
                maxWidth: "500px",
                paddingBottom: "64px",
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <BoxTypo fs="14px">黒ラベル小瓶</BoxTypo>
                  </td>
                  <td style={{ width: "30%" }}>
                    <BoxTypo fs="14px">600円</BoxTypo>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BoxTypo fs="14px">エビス小瓶エビス小瓶</BoxTypo>
                  </td>
                  <td>
                    <BoxTypo fs="14px">650円</BoxTypo>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BoxTypo fs="14px">リキュール各種</BoxTypo>
                  </td>
                  <td>
                    <BoxTypo fs="14px">750円〜1,000円</BoxTypo>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BoxTypo fs="14px">グラスワイン各種</BoxTypo>
                  </td>
                  <td>
                    <BoxTypo fs="14px">500円〜1,500円</BoxTypo>
                  </td>
                </tr>
              </tbody>
            </table>
            <BoxTypo
              fs="12px"
              textAlign="right"
              style={{
                width: "90%",
                margin: "auto",
                marginTop: "32px",
                maxWidth: "500px",
                paddingBottom: "32px",
              }}
            >
              (税抜き表示)
            </BoxTypo>
          </Box>
          <Box>
            <Box m="50px" textAlign="center">
              <BoxTypo fs={["24px", "36px", "48px"]} fw="700">
                “Pictures .
              </BoxTypo>
              <BoxTypo fs="14px">お料理の様子</BoxTypo>
            </Box>
            <Pics />
          </Box>
          <NewsArea />
          <Box width="100%" bgcolor="#b8a07c" marginTop="64px">
            <BoxTypo
              color="#fff"
              fs={["24px", "36px", "48px"]}
              width={["90%", "70%", "60%"]}
              m={"auto"}
              fw="bold"
            >
              “Store info.
            </BoxTypo>
            <BoxTypo
              color="#fff"
              fs={["11px", "12px", "14px"]}
              width={["90%", "70%", "55%"]}
              m={"auto"}
              fw="900"
              ls="10px"
            >
              店舗情報
            </BoxTypo>
          </Box>
          <Box
            display={["block", "block", "block", "flex"]}
            width={["100%", "90%", "70%"]}
            m={"auto"}
            py={["40px", "50px", "60px"]}
          >
            <Box
              display="flex"
              justifyContent="center"
              width={["100%", "100%", "100%", "70%"]}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
              >
                {companyLists.map((list, index) => (
                  <BoxTypo key={index} fs={["12px", "16px", "18px"]} lh="2.5">
                    {list}
                  </BoxTypo>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                pl={["20px", "30px", "40px"]}
              >
                {companyListsSec.map((list, index) => (
                  <BoxTypo key={index} fs={["12px", "16px", "18px"]} lh="2.5">
                    {list}
                  </BoxTypo>
                ))}
              </Box>
            </Box>
            <MediaQuery query="(max-width: 768px)">
              <Box>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.324022408544!2d139.71927811514388!3d35.64438768020293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b11e38775e5%3A0x3c7e4da6cfb3d98b!2z44CSMTA4LTAwNzIg5p2x5Lqs6YO95riv5Yy655m96YeR77yW5LiB55uu77yS77yT4oiS77yR!5e0!3m2!1sja!2sjp!4v1626344166722!5m2!1sja!2sjp"
                  width="100%"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Box>
            </MediaQuery>

            <MediaQuery query="(min-width: 769px)">
              <Box display="flex" justifyContent="center">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.324022408544!2d139.71927811514388!3d35.64438768020293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b11e38775e5%3A0x3c7e4da6cfb3d98b!2z44CSMTA4LTAwNzIg5p2x5Lqs6YO95riv5Yy655m96YeR77yW5LiB55uu77yS77yT4oiS77yR!5e0!3m2!1sja!2sjp!4v1626344166722!5m2!1sja!2sjp"
                  width="500"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Box>
            </MediaQuery>
          </Box>
          <Box
            width="500px"
            maxWidth="300px"
            m={"auto"}
            display="flex"
            justifyContent="space-around"
          >
            <Link
              to="https://ja-jp.facebook.com/macaone.platino"
              target="_blank"
              rel="noopener noreferrer"
              width="300px"
            >
              <Img
                fluid={data.FB.childImageSharp.fluid}
                style={{ width: "60px", height: "auto" }}
              />
            </Link>
            <Link
              to="https://www.instagram.com/il_macaone/"
              target="_blank"
              rel="noopener noreferrer"
              width="100px"
            >
              <Img
                fluid={data.IG.childImageSharp.fluid}
                style={{ width: "60px", height: "auto" }}
              />
            </Link>
          </Box>
        </Box>
        {/* 2ページ目 */}

        <Footer />
      </Box>
    </>
  );
}
