import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Box from "@material-ui/core/Box";

const Pictures = () => {
  const data = useStaticQuery(graphql`
    query {
      media1: file(relativePath: { eq: "S__15720470.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media2: file(relativePath: { eq: "S__15720475.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media3: file(relativePath: { eq: "S__15720474.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media4: file(relativePath: { eq: "S__15720477.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media5: file(relativePath: { eq: "S__15720478.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media6: file(relativePath: { eq: "S__15720476.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media7: file(relativePath: { eq: "S__15720472.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media8: file(relativePath: { eq: "S__15720479.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media9: file(relativePath: { eq: "S__15720480.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media10: file(relativePath: { eq: "S__15720482.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const picName = [
    data.media1.childImageSharp.fluid,
    data.media2.childImageSharp.fluid,
    data.media3.childImageSharp.fluid,
    data.media4.childImageSharp.fluid,
    data.media5.childImageSharp.fluid,
    data.media6.childImageSharp.fluid,
    data.media7.childImageSharp.fluid,
    data.media8.childImageSharp.fluid,
    data.media9.childImageSharp.fluid,
    data.media10.childImageSharp.fluid,
  ];
  return (
    <>
      <Box
        width="100%"
        textAlign="center"
        mt={5}
        display="flex"
        justifyContent="center"
      >
        <Box width="100%" maxWidth="1000px" display="flex" flexWrap="wrap">
          {picName.map((c, i) => {
            return (
              <Box width={i % 3 === 0 ? "100%" : "50%"}>
                <Img
                  fluid={c}
                  style={{
                    height: "350px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default Pictures;
